html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
}
.invoice__preview {
  margin-top: 1.25rem;
}

@media (min-width: 1280px) {
  .invoice__preview {
    transform: scale(0.8);
    position: sticky;
    right: 0;
    top: 0;
    margin-top: -6rem;
    margin-bottom: 2rem;
  }
}